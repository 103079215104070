import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tabs, Select, List, Row, Col, Modal, notification,
} from 'antd';

import AuthorRow from '../Users/AuthorRow';
import AttractionRow from '../Attractions/AttractionRow';
import TourRow from '../Tours/TourRow';
import {
  $addAuthorAssignment, $removeAuthorAssignment,
} from '../Shared/state';
import { $fetchTourList, $removeTour } from '../Tours/state';
import { $fetchAttractionList, $removeAttraction } from '../Attractions/state';
import { ASSETS_ENDPOINT } from '../common/config';
import { canAssignAuthors } from '../common/permissions';
import './SiteView.css';

const withStore = connect((state, props) => ({
  site: state.Sites.list.find((site) => site._id === props.match.params.id),
  tours: state.Tours.list
    ? state.Tours.list.filter((tour) => tour.site._id === props.match.params.id)
    : [],
  attractions: state.Attractions.list
    ? state.Attractions.list.filter((attraction) => attraction.site._id === props.match.params.id)
    : [],
  users: state.Users.list,
  user: state.Auth.user,
  assignments: state.Shared.assignments
    ? state.Shared.assignments.list.filter((a) => a.site._id === props.match.params.id)
    : [],
  hasAssignAuthorsPersmission: canAssignAuthors(state.Auth),
}));

const Wrapper = (C) => withStore(C);

class SiteView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchTourList());
    dispatch($fetchAttractionList());
  }

  handleAddAuthor = (authorId) => {
    const { dispatch, site } = this.props;

    const siteMap = {
      _id: site._id,
      display: site.name,
      link: 'Site',
    };

    dispatch($addAuthorAssignment(siteMap, authorId));
  }

  handleRemoveAuthor = (authorId) => {
    const { dispatch, assignments } = this.props;

    const assignment = assignments.find((a) => a.author === authorId);
    dispatch($removeAuthorAssignment(assignment._id));
  }

  handleDeleteAttraction = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer ce lieux ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeAttraction(id))
          .then(() => {
            notification.success({ message: 'Le lieux a été supprimé avec succès' });
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  handleDeleteTour = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer ce parcours ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeTour(id))
          .then(() => {
            notification.success({ message: 'Le parcours a été supprimé avec succès' });
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  render() {
    const {
      site, users, user, tours, attractions, assignments,
      hasAssignAuthorsPersmission,
    } = this.props;

    const isAdmin = user.group === 'admin';
    const createdBy = users.find((user) => user._id === site._by);

    return (
      <div className="SiteView">
        <h1 className="SiteView__title">{site.name}</h1>

        <div className="SiteView__content">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Aperçu" key="1">
              <Row type="flex" justify="space-between">
                <Col span="11">
                  <img
                    src={`${ASSETS_ENDPOINT}/${site.picture.path}`}
                    alt="site cover"
                    width="100%"
                  />
                </Col>

                <Col span="11">
                  <div>
                    <div className="SiteView__label">Description</div>
                    <p
                      className="SiteView__text"
                      dangerouslySetInnerHTML={{ __html: site.description }}
                    />
                  </div>
                  <div>
                    <div className="SiteView__label">Date de création</div>
                    <p className="SiteView__text">
                      {new Date(site._created * 1000).toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <div className="SiteView__label">Ajouté par</div>
                    <p className="SiteView__text">
                      {createdBy ? createdBy.name : 'Inconnu'}
                    </p>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Lieux" key="3">
              <List
                itemLayout="horizontal"
                dataSource={attractions}
                renderItem={(attraction) => (
                  <AttractionRow
                    attraction={attraction}
                    manageable={isAdmin}
                    onDelete={this.handleDeleteAttraction}
                  />
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Parcours" key="2">
              <List
                itemLayout="horizontal"
                dataSource={tours}
                renderItem={(tour) => (
                  <TourRow
                    tour={tour}
                    manageable={isAdmin}
                    onDelete={this.handleDeleteTour}
                  />
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Conservateurs" key="4">
              {hasAssignAuthorsPersmission && (
                <Select
                  mode="multiple"
                  placeholder="Ajouter conservateurs"
                  style={{ width: '100%' }}
                  defaultValue={assignments.map((a) => a.author).filter((a) => users.find((user) => user._id === a))}
                  onSelect={this.handleAddAuthor}
                  onDeselect={this.handleRemoveAuthor}
                >
                  {users
                    .filter((user) => user.group !== 'admin')
                    .map((user) => (
                      <Select.Option
                        key={user._id}
                        value={user._id}
                      >
                        {user.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              )}

              <div style={{ marginTop: '14px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={assignments.filter((a) => users.find((user) => user._id === a.author))}
                  renderItem={(assignment) => {
                    const user = users.find((u) => u._id === assignment.author);
                    return <AuthorRow user={user} />;
                  }}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

SiteView.propTypes = {
  site: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  tours: PropTypes.array.isRequired,
  attractions: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  hasAssignAuthorsPersmission: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(SiteView);
