import { API_ENDPOINT } from '../common/config';

import * as FetchHelper from '../common/fetch.helper';
import * as StateHelper from '../common/state.helper';

import { AuthService } from '../Auth/Auth.service';

import * as Activity from '../Shared/Activity.state';

/**
 * Module Name
 */

export const MODULE = 'Tours';

/**
 * Initial State
 */

const INITIAL_STATE = {
  list: [],
  fields: [],
  total: 0,
};

/**
 * Reset
 */

const reset = StateHelper.createSimpleOperation(MODULE, 'reset');

export const $reset = reset.action;

/**
 * Fetch tours list
 */

const fetchTourList = StateHelper.createAsyncOperation(MODULE, 'fetchTourList');

export function $fetchTourList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchTourList.name));
    dispatch(fetchTourList.request());

    return fetch(`${API_ENDPOINT}/api/collections/get/Tour?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchTourList.success({ result })))
      .catch((error) => dispatch(fetchTourList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchTourList.name)));
  };
}

/**
 * New Tour
 */

const createTour = StateHelper.createAsyncOperation(MODULE, 'createTour');

export function $createTour(payload) {
  return (dispatch, getState) => {
    dispatch(Activity.$processing(MODULE, $createTour.name));
    dispatch(createTour.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Tour?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          ...payload,
          _by: {
            id: getState().Auth.user._id,
            name: getState().Auth.user.name,
          },
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .catch((error) => dispatch(createTour.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createTour.name)));
  };
}

/**
 * Edit Tour
 */

const editTour = StateHelper.createAsyncOperation(MODULE, 'editTour');

export function $editTour(id, payload) {
  return (dispatch, getState) => {
    dispatch(Activity.$processing(MODULE, $editTour.name));
    dispatch(editTour.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Tour?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          _mby: {
            id: getState().Auth.user._id,
            name: getState().Auth.user.name,
          },
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .catch((error) => dispatch(editTour.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editTour.name)));
  };
}

/**
 * Remove Attraction
 */

const removeTour = StateHelper.createAsyncOperation(MODULE, 'removeTour');

export function $removeTour(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeTour.name));
    dispatch(removeTour.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Tour?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeTour.success({ result })))
      .then(() => dispatch($fetchTourList()))
      .catch((error) => dispatch(removeTour.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeTour.name)));
  };
}

/**
 * Reducer
 */

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reset.TYPE:
      return INITIAL_STATE;
    case fetchTourList.SUCCESS:
      return {
        ...state,
        list: action.result.entries,
        fields: action.result.fields,
        total: action.result.total,
      };

    default:
      return state;
  }
}

/**
 * Persister
 */

export function persister({ list }) {
  return { list };
}
