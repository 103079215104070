import React from 'react';
import PropTypes from 'prop-types';

import './PhonePreview.css';

const PhonePreview = ({ children }) => {
  return (
    <div className="PhonePreview">
      <div className="PhonePreview__content">
        {children}
      </div>
    </div>
  );
};

PhonePreview.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PhonePreview;
