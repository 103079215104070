import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  List, Icon, Button, Avatar, notification, Row, Col, Modal, Tooltip,
} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { truncate } from 'humanize-plus';

import {
  $removeSite, $fetchSiteList, $editSite, $reorderSites,
} from './state';
import { canManageSites } from '../common/permissions';
import { ASSETS_ENDPOINT } from '../common/config';

const TRALING = 8;

const withStore = connect((state) => ({
  processing: state.Activity.processingByTopic['Site.$fetchSiteList'] || false,
  sites: state.Sites.list,
  hasManageSitesPermission: canManageSites(state.Auth),
}));

const Wrapper = (C) => withStore(C);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#ccc' : 'transparent',
});

class SitesView extends Component {
  handleDeleteSite = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer ce site ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeSite(id))
          .then(() => {
            notification.success({ message: 'Le site a été supprimé avec succès' });
            dispatch($fetchSiteList());
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return null;
    }

    const { sites, dispatch } = this.props;

    const newSites = reorder(
      sites,
      result.source.index,
      result.destination.index,
    );

    // Skip updates if ordering did not change
    if (newSites.map((site) => site._id).toString()
      === sites.map((site) => site._id).toString()) {
      return null;
    }

    dispatch($reorderSites(newSites));

    Promise.all(newSites.map((s, index) => {
      // eslint-disable-next-line prefer-destructuring
      const length = String(index).length;
      return dispatch($editSite(s._id, {
        order: new Array(TRALING - length).fill(0).join('') + index,
      }));
    })).then(() => {
      notification.success({ message: 'Les sites ont été réordonnés avec succès' });
    });
  }

  render() {
    const { sites, hasManageSitesPermission } = this.props;

    return (
      <Fragment>
        {hasManageSitesPermission && (
          <Row type="flex" justify="end" style={{ marginBottom: 12 }}>
            <Button type="primary">
              <Link to="/sites/new">Créer un site</Link>
            </Button>
          </Row>
        )}

        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                <List
                  itemLayout="horizontal"
                  dataSource={sites}
                  renderItem={(item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                      isDragDisabled={!hasManageSitesPermission}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <List.Item
                            className="ListItem"
                            actions={hasManageSitesPermission
                              ? [
                                <Link to={`/sites/${item._id}/new-tour`}>
                                  <Button>Ajouter parcours</Button>
                                </Link>,
                                <Button
                                  icon="delete"
                                  onClick={() => this.handleDeleteSite(item._id)}
                                  style={{ border: 'none' }}
                                />,
                                <Link to={`/sites/${item._id}/edit`}>
                                  <Icon type="edit" />
                                </Link>,
                              ] : []
                            }
                          >
                            <Row type="flex" align="middle" style={{ width: '100%' }}>
                              <Col span={12}>
                                <List.Item.Meta
                                  avatar={(
                                    <Avatar src={`${ASSETS_ENDPOINT}/${item.picture.path}`} />
                                  )}
                                  title={(
                                    <Link to={`/sites/${item._id}`}>
                                      {item.name}
                                      {' - '}
                                      {item.zone.display}
                                    </Link>
                                  )}
                                  description={(
                                    // eslint-disable-next-line react/no-danger
                                    <div dangerouslySetInnerHTML={{
                                      __html: truncate(item.description, 100),
                                    }}
                                    />
                                  )}
                                />
                              </Col>


                              <Col span={3}>
                                {/* PHP dates */}
                                <Tooltip title="Créé le">
                                  {new Date(item._created * 1000).toLocaleDateString()}
                                </Tooltip>
                              </Col>

                              {item.createdBy && (
                                <Col span={6}>
                                  <Avatar>
                                    {item.createdBy.name.substring(0, 2).toUpperCase()}
                                  </Avatar>
                                  {' '}
                                  <Tooltip title="Créé par">
                                    {item.createdBy.name}
                                  </Tooltip>
                                </Col>
                              )}
                            </Row>
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Fragment>
    );
  }
}

SitesView.propTypes = {
  sites: PropTypes.array.isRequired,
  hasManageSitesPermission: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(SitesView);
