import React from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb as AntdBreadcrumb, Icon } from 'antd';

export const Breadcrumb = ({ routes, location }) => {
  let previouslyMatched = '';
  const breadcrumbItems = routes.map((route) => {
    // If 2 route paths have same length, skip displaying breadcrumb
    const routeLength = route.path.split('/').length;
    const match = matchPath(location.pathname, { path: route.path });
    if (!match || previouslyMatched === routeLength) {
      return null;
    }

    previouslyMatched = routeLength;

    return (
      <AntdBreadcrumb.Item key={match.url}>
        <Link to={match.url}>
          {route.icon && <Icon type={route.icon} />}
          {<span style={{ marginLeft: route.icon ? '4px' : 0 }}>{route.name}</span>}
        </Link>
      </AntdBreadcrumb.Item>
    );
  });

  return (
    <AntdBreadcrumb style={{ marginBottom: '3em' }}>
      {breadcrumbItems}
    </AntdBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Breadcrumb);
