import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col, Card, Statistic, Button, Tag, Modal, Menu, Dropdown, Icon, List,
} from 'antd';

import CategoryForm from '../Shared/CategoryForm';
import SubCategoryForm from '../Shared/SubCategoryForm';
import ZoneForm from '../Shared/ZoneForm';
import { $fetchSiteList } from '../Sites/state';
import { $removeCategory, $removeZone, $removeSubCategory } from '../Shared/state';
import {
  canManageSites,
  canManageCategories,
  canManageZones,
} from '../common/permissions';
import './style.css';

const withStore = connect((state) => ({
  sites: state.Sites.list,
  categories: state.Shared.categories.list,
  subCategories: state.Shared.subCategories.list,
  zones: state.Shared.zones.list,
  hasManageSitesPermission: canManageSites(state.Auth),
  hasManageCategoriesPermission: canManageCategories(state.Auth),
  hasManageZonesPermission: canManageZones(state.Auth),
}));

const Wrapper = (C) => withStore(C);

class Home extends Component {
  state = {
    showCategoryModal: false,
    currentCategoryId: null,
    showZoneModal: false,
    currentZoneId: null,
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchSiteList());
  }

  handleOpenModal = (type, id = null) => {
    switch (type) {
      case 'category':
        this.setState({
          showCategoryModal: true,
          currentCategoryId: id,
        });
        break;
      case 'subcategory':
        this.setState({
          showSubCategoryModal: true,
          currentSubCategoryId: id,
        });
        break;
      case 'zone':
        this.setState({
          showZoneModal: true,
          currentZoneId: id,
        });
        break;
      default:
        break;
    }
  }

  handleCloseModal = () => {
    this.setState({
      showCategoryModal: false,
      currentCategoryId: null,
      showSubCategoryModal: false,
      currentSubCategoryId: null,
      showZoneModal: false,
      currentZoneId: null,
    });
  }

  handleDeleteCategoryClick = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer cette catégories ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeCategory(id));
      },
      onCancel() {},
    });
  }

  handleDeleteSubCategoryClick = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer cette sous catégorie ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeSubCategory(id));
      },
      onCancel() {},
    });
  }

  handleDeleteZoneClick = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer cette zone ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeZone(id));
      },
      onCancel() {},
    });
  }

  render() {
    const {
      showCategoryModal,
      currentCategoryId,
      showSubCategoryModal,
      currentSubCategoryId,
      showZoneModal,
      currentZoneId,
    } = this.state;
    const {
      sites,
      categories,
      subCategories,
      zones,
      hasManageSitesPermission,
      hasManageCategoriesPermission,
      hasManageZonesPermission,
    } = this.props;

    return (
      <Fragment>
        <Row gutter={16}>
          {/* XXX Can an author create a site ? */}
          <Col span={6}>
            <Card className="Home__card">
              <Statistic title="Sites" value={sites.length} />

              {hasManageSitesPermission && (
                <Link to="/sites/new">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="plus"
                    className="Home__add"
                  />
                </Link>
              )}
            </Card>
          </Col>

          <Col span={6}>
            <Card title="Catégories" className="Home__card">
              {hasManageCategoriesPermission && (
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  className="Home__add"
                  onClick={() => this.handleOpenModal('category')}
                />
              )}
              {categories.map((category) => {
                const tag = (
                  <Tag
                    style={{ backgroundColor: category.color }}
                    className="HomeCategory__tag"
                  >
                    {category.name}
                    {hasManageCategoriesPermission && <Icon type="down" />}
                  </Tag>
                );

                if (!hasManageCategoriesPermission) {
                  return tag;
                }

                return (
                  <Dropdown
                    key={category._id}
                    trigger={['click']}
                    overlay={(
                      <Menu>
                        <Menu.Item
                          key="edit"
                          onClick={() => this.handleOpenModal('category', category._id)}
                        >
                          Editer
                          <Icon type="edit" />
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          onClick={() => this.handleDeleteCategoryClick(category._id)}
                        >
                          Supprimer
                          <Icon type="delete" />
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {tag}
                  </Dropdown>
                );
              })}
            </Card>
          </Col>

          <Col span={6}>
            <Card title="Sous catégories" className="Home__card">
              {hasManageCategoriesPermission && (
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  className="Home__add"
                  onClick={() => this.handleOpenModal('subcategory')}
                />
              )}
              {subCategories.map((subCategory) => {
                const tag = (
                  <Tag
                    style={{ backgroundColor: subCategory.category ? subCategory.category.color : '' }}
                    className="HomeCategory__tag"
                  >
                    {subCategory.name}
                    {hasManageCategoriesPermission && <Icon type="down" />}
                  </Tag>
                );

                if (!hasManageCategoriesPermission) {
                  return tag;
                }

                return (
                  <Dropdown
                    key={subCategory._id}
                    trigger={['click']}
                    overlay={(
                      <Menu>
                        <Menu.Item
                          key="edit"
                          onClick={() => this.handleOpenModal('subcategory', subCategory._id)}
                        >
                          Editer
                          <Icon type="edit" />
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          onClick={() => this.handleDeleteSubCategoryClick(subCategory._id)}
                        >
                          Supprimer
                          <Icon type="delete" />
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {tag}
                  </Dropdown>
                );
              })}
            </Card>
          </Col>

        </Row>

        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={6}>
            <Card title="Zones" className="Home__card">
              {hasManageZonesPermission && (
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  className="Home__add"
                  onClick={() => this.handleOpenModal('zone')}
                />
              )}
              <List
                dataSource={zones}
                renderItem={(zone) => (
                  <List.Item
                    actions={hasManageZonesPermission
                      ? [
                        <Button
                          icon="edit"
                          shape="circle"
                          size="small"
                          onClick={() => this.handleOpenModal('zone', zone._id)}
                        />,
                        <Button
                          icon="delete"
                          shape="circle"
                          size="small"
                          type="danger"
                          onClick={this.handleDeleteZoneClick}
                        />,
                      ] : []}
                  >
                    {zone.name}
                  </List.Item>
                )}
              />
            </Card>
          </Col>

          {/* <Col span={12}>
            <Card title="Add a tour">
              <List
                size="small"
                dataSource={sites}
                renderItem={(site) => (
                  <List.Item
                    className="Home__tour-item"
                    actions={[
                      <Dropdown
                        trigger={['click']}
                        overlay={(
                          <Menu>
                            {categories.map((category) => (
                              <Menu.Item
                                key={category._id}
                              >
                                <Link to={`/tours/new?siteId=${site._id}&categoryId=${category._id}`}>
                                  {category.name}
                                </Link>
                              </Menu.Item>
                            ))}
                          </Menu>
                        )}
                      >
                        <Button
                          type="primary"
                          size="small"
                          className="Home__add-tour"
                          icon="compass"
                        >
                          Add a tour
                        </Button>
                      </Dropdown>,
                    ]}
                  >
                    {site.name}
                  </List.Item>
                )}
              />
            </Card>
          </Col> */}
        </Row>

        <Modal
          key={currentCategoryId || 'category-modal'}
          title={currentCategoryId ? 'Editer catégorie' : 'Créer catégorie'}
          visible={showCategoryModal}
          footer={null}
          onCancel={this.handleCloseModal}
        >
          <CategoryForm
            id={currentCategoryId}
            onCloseModal={this.handleCloseModal}
          />
        </Modal>

        <Modal
          key={currentSubCategoryId || 'subcategory-modal'}
          title={currentSubCategoryId ? 'Editer sous catégories' : 'Créer sous categorie'}
          visible={showSubCategoryModal}
          footer={null}
          onCancel={this.handleCloseModal}
        >
          <SubCategoryForm
            id={currentSubCategoryId}
            onCloseModal={this.handleCloseModal}
          />
        </Modal>

        <Modal
          key={currentZoneId || 'zone-modal'}
          title={currentZoneId ? 'Editer zone' : 'Créer zone'}
          visible={showZoneModal}
          footer={null}
          onCancel={this.handleCloseModal}
        >
          <ZoneForm
            id={currentZoneId}
            onCloseModal={this.handleCloseModal}
          />
        </Modal>
      </Fragment>
    );
  }
}

Home.propTypes = {
  sites: PropTypes.array.isRequired,
  attractions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  hasManageSitesPermission: PropTypes.bool.isRequired,
  hasManageCategoriesPermission: PropTypes.bool.isRequired,
  hasManageZonesPermission: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(Home);
