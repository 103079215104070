import { API_ENDPOINT } from '../common/config';

import * as FetchHelper from '../common/fetch.helper';
import * as StateHelper from '../common/state.helper';

import { AuthService } from '../Auth/Auth.service';

import * as Activity from '../Shared/Activity.state';

/**
 * Module Name
 */

export const MODULE = 'Attractions';

/**
 * Initial State
 */

const INITIAL_STATE = {
  list: [],
  fields: {},
  total: 0,
};

/**
 * Reset
 */

const reset = StateHelper.createSimpleOperation(MODULE, 'reset');

export const $reset = reset.action;

/**
 * Fetch Attractions
 */

const fetchAttractionList = StateHelper.createAsyncOperation(MODULE, 'fetchAttractionList');

// async/await implementation
export function $fetchAttractionList() {
  return async (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchAttractionList.name));
    dispatch(fetchAttractionList.request());

    try {
      const response = await fetch(`${API_ENDPOINT}/api/collections/get/Attraction?token=${AuthService.getAccessToken()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await FetchHelper.ResponseHandler(response);

      return dispatch(fetchAttractionList.success({ result }));
    } catch (error) {
      await FetchHelper.ErrorValueHandler(error);
      dispatch(fetchAttractionList.failure(error));
    } finally {
      dispatch(Activity.$done(MODULE, $fetchAttractionList.name));
    }
  };
}

/**
 * New Attraction
 */

const createAttraction = StateHelper.createAsyncOperation(MODULE, 'createAttraction');

export function $createAttraction(payload) {
  return (dispatch, getState) => {
    dispatch(Activity.$processing(MODULE, $createAttraction.name));
    dispatch(createAttraction.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Attraction?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          ...payload,
          _by: {
            id: getState().Auth.user._id,
            name: getState().Auth.user.name,
          },
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .catch((error) => dispatch(createAttraction.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createAttraction.name)));
  };
}

/**
 * Edit Attraction
 */

const editAttraction = StateHelper.createAsyncOperation(MODULE, 'editAttraction');

export function $editAttraction(id, payload) {
  return (dispatch, getState) => {
    dispatch(Activity.$processing(MODULE, $editAttraction.name));
    dispatch(editAttraction.request());

    return fetch(`${API_ENDPOINT}/api/collections/save/Attraction?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: id,
          _mby: {
            id: getState().Auth.user._id,
            name: getState().Auth.user.name,
          },
          ...payload,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .catch((error) => dispatch(editAttraction.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editAttraction.name)));
  };
}

/**
 * Remove Attraction
 */

const removeAttraction = StateHelper.createAsyncOperation(MODULE, 'removeAttraction');

export function $removeAttraction(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeAttraction.name));
    dispatch(removeAttraction.request());

    return fetch(`${API_ENDPOINT}/api/collections/remove/Attraction?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          _id: id,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(removeAttraction.success({ result })))
      .then(() => dispatch($fetchAttractionList()))
      .catch((error) => dispatch(removeAttraction.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeAttraction.name)));
  };
}

/**
 * Reducer
 */

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reset.TYPE:
      return INITIAL_STATE;
    case fetchAttractionList.SUCCESS:
      return {
        ...state,
        list: action.result.entries,
        fields: action.result.fields,
        total: action.result.total,
      };
    default:
      return state;
  }
}

/**
 * Persister
 */

export function persister({ list }) {
  return { list };
}
