import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Upload, Icon, notification, message, Avatar, Form, Input, Button,
} from 'antd';

import { $editUser } from './state';
import { API_ENDPOINT, ASSETS_ENDPOINT } from '../common/config';
import { AuthService } from '../Auth/Auth.service';
import './ProfileView.css';

const withStore = connect((state) => {
  return {
    processing: state.Activity.processingByTopic['Users.$fetchUserList'] || false,
    user: state.Auth.user,
  };
});

const Wrapper = (C) => withStore(C);

function beforeUpload(file) {
  const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isImage) {
    message.error('You can only upload image files!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isImage && isLt2M;
}

class ProfileView extends Component {
  state = {
    loadingAvatar: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      user, form, dispatch,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      dispatch($editUser(user._id, values))
        .then(() => {
          notification.success({ message: 'Mis à jour avec succès !' });
        });
    });
  }

  handleChangeAvatar = (info) => {
    const { user, dispatch } = this.props;

    if (info.file.status === 'uploading') {
      this.setState({ loadingAvatar: true });
      return;
    }
    if (info.file.status === 'done') {
      dispatch($editUser(user._id, {
        avatarUrl: info.file.response.assets[0].path,
      })).catch(() => {
        notification.error({ message: 'Une erreur est survenue :(' });
      });

      this.setState({ loadingAvatar: false });
    }
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  renderAvatar() {
    const { loadingAvatar } = this.state;
    const { user } = this.props;

    if (loadingAvatar) {
      return <Icon type="loading" />;
    }

    if (user.avatarUrl) {
      return (
        <Avatar src={`${ASSETS_ENDPOINT}/${user.avatarUrl}`} size={64} />
      );
    }

    return (
      <Icon type="plus" />
    );
  }

  render() {
    const { user, form } = this.props;

    const nameHasError = this.hasError('name');
    const usernameHasError = this.hasError('user');
    const emailHasError = this.hasError('email');

    return (
      <div className="ProfileView">
        <div className="ProfileView__top">
          <Upload
            name="files[]"
            listType="picture-card"
            className="ProfileView__upload"
            showUploadList={false}
            action={`${API_ENDPOINT}/api/cockpit/addAssets?token=${AuthService.getAccessToken()}`}
            beforeUpload={beforeUpload}
            onChange={this.handleChangeAvatar}
          >
            {this.renderAvatar()}
          </Upload>

          <h1 className="ProfileView__name">{user.name}</h1>
        </div>

        <div className="ProfileView__form">
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              key="name"
              label="Name"
              validateStatus={nameHasError ? 'error' : 'success'}
              help={nameHasError || ''}
            >
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input your fullname' }],
                initialValue: user ? user.name : '',
              })(
                <Input placeholder="Your fullname" />,
              )}
            </Form.Item>

            <Form.Item
              key="user"
              label="Username"
              validateStatus={usernameHasError ? 'error' : 'success'}
              help={usernameHasError || ''}
            >
              {form.getFieldDecorator('user', {
                rules: [{ required: true, message: 'Please input username' }],
                initialValue: user ? user.user : '',
              })(
                <Input placeholder="Username" />,
              )}
            </Form.Item>

            <Form.Item
              key="email"
              label="Email"
              validateStatus={emailHasError ? 'error' : 'success'}
              help={emailHasError || ''}
            >
              {form.getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input  youruser email' }],
                initialValue: user ? user.email : '',
              })(
                <Input placeholder="Your email" />,
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

ProfileView.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const WrappedProfileView = Form.create({ name: 'profile_form' })(ProfileView);

export default Wrapper(WrappedProfileView);