import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import ToursView from './ToursView';
import TourForm from './TourForm';
import Breadcrumb from '../components/Breadcrumb';

const routes = [{
  path: '/tours',
  component: ToursView,
  name: 'Parcours',
  icon: 'compass',
}, {
  name: 'Créer',
  path: '/tours/new',
  component: TourForm,
}, {
  name: 'Editer',
  path: '/tours/:id/edit',
  component: TourForm,
  exact: true,
}];

const Tour = () => {
  return (
    <Fragment>
      <Breadcrumb routes={routes} />

      <Switch>
        {routes.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}
      </Switch>
    </Fragment>
  );
};

export default Tour;
