/**
 * State
 *
 * This module is for state definition
 */

import * as Activity from '../Shared/Activity.state';
import * as Shared from '../Shared/state';
import * as Auth from '../Auth/state';
import * as Session from '../Session/state';
import * as Sites from '../Sites/state';
import * as Tours from '../Tours/state';
import * as Attractions from '../Attractions/state';
import * as Users from '../Users/state';

const $state = {
  Activity,
  Shared,
  Auth,
  Session,
  Sites,
  Tours,
  Attractions,
  Users,
};

export default $state;
