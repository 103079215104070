import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  List, Skeleton, Avatar, Icon, Button,
} from 'antd';

import { API_ENDPOINT } from '../common/config';

const TourRow = ({ tour, manageable, onDelete }) => {
  return (
    <List.Item
      className="ListItem"
      actions={manageable ? [
        <Button
          icon="delete"
          onClick={() => onDelete(tour._id)}
          style={{ border: 'none' }}
        />,
        <Link to={`/tours/${tour._id}/edit?siteId=${tour.site._id}&categoryId=${tour.category._id}`}>
          <Icon type="edit" />
        </Link>,
      ] : []}
    >
      <Skeleton avatar title={false} loading={false} active>
        <List.Item.Meta
          avatar={<Avatar shape="square" src={`${API_ENDPOINT}/storage/uploads/${tour.picture.path}`} />}
          title={(
            manageable ? (
              <Link to={`/tours/${tour._id}/edit?siteId=${tour.site._id}&categoryId=${tour.category._id}`}>
                {tour.name}
              </Link>
            ) : (
              <span>{tour.name}</span>
            )
          )}
          description={<div dangerouslySetInnerHTML={{ __html: tour.description }} />}
        />
      </Skeleton>
    </List.Item>
  );
};

TourRow.propTypes = {
  tour: PropTypes.object.isRequired,
  manageable: PropTypes.bool,
  onDelete: PropTypes.func,
};

TourRow.defaultProps = {
  manageable: false,
  onDelete: () => {},
};

export default TourRow;
