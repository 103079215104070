import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Select, Form, Button, Upload, Icon, message, Row, Col, List, notification,
} from 'antd';
import ReactQuill from 'react-quill';
import L from 'leaflet';
import {
  Map, TileLayer, GeoJSON, FeatureGroup,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import FullscreenControl from 'react-leaflet-fullscreen';

import PhonePreview from '../components/PhonePreview';
import { $editSite, $createSite, $fetchSiteList } from './state';
import { $uploadFile } from '../Shared/state';
import { ASSETS_ENDPOINT } from '../common/config';
import { isValidImage } from '../common/validate';
import polygone from '../assets/polygone.png';

import './SiteForm.css';

const DEFAULT_MAP_CENTER = [36.808286, 10.181066];
const DEFAULT_MAP_ZOOM = 14;

const mapZone = (zone) => ({
  link: 'Zone',
  _id: zone._id,
  display: zone.name,
});

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 18, offset: 4 },
};

const withStore = connect((state, props) => ({
  processing: state.Activity.processingByTopic['Sites.$fetchSiteList'] || false,
  sites: state.Sites.list,
  site: state.Sites.list.find((site) => site._id === props.match.params.id),
  categories: state.Shared.categories.list,
  zones: state.Shared.zones.list,
}));

const Wrapper = (C) => withStore(C);

class SiteForm extends Component {
  refmarker = createRef();

  state = {
    isUploading: false,
    previewDataSrc: this.props.site
      ? `${ASSETS_ENDPOINT}/${this.props.site.picture.path}`
      : '',
  };

  componentDidMount() {
    const { form } = this.props;
    form.validateFields();
  }

  getMapCenter() {
    const { site, zones, form } = this.props;

    if (site && site.location) {
      return [site.location.lat, site.location.lng];
    }

    const selectedZone = zones.find((zone) => zone._id === form.getFieldValue('zone'));
    if (selectedZone && selectedZone.location) {
      return [selectedZone.location.lat, selectedZone.location.lng];
    }

    return DEFAULT_MAP_CENTER;
  }

  handleBeforeUpload = (file) => {
    if (!isValidImage(file)) {
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({
        previewDataSrc: reader.result,
      });
    };

    return false;
  }

  handlePolygonCreated = (e) => {
    const { form } = this.props;

    form.setFieldsValue({
      geoJSON: JSON.stringify(e.layer.toGeoJSON()),
      location: JSON.stringify(L.latLngBounds(e.layer._bounds).getCenter()),
    });
  }

  handlePolygonDeleted = () => {
    const { form } = this.props;
    form.setFieldsValue({ geoJSON: null, location: null });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      site, form, history, dispatch,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      let isValid = true;
      if (values.imageFile && values.imageFile.file) {
        isValid = isValidImage(values.imageFile.file);
      }

      if (!isValid) {
        message.error('Merci de sélectionner un fichier valide');
        return null;
      }

      this.setState({ isUploading: true });
      this.uploadFileBeforeSubmit(values).then((valuesWithAssets) => {
        this.setState({ isUploading: false });

        const newValues = this.mapFormValues(valuesWithAssets);

        if (site) {
          dispatch($editSite(site._id, newValues))
            .then(() => {
              dispatch($fetchSiteList());
              notification.success({ message: 'Le site a été édité avec succès!' });
              history.push('/sites');
            });
        } else {
          dispatch($createSite(newValues))
            .then(() => {
              dispatch($fetchSiteList());
              notification.success({ message: 'Le site a été créé avec succès!' });
              history.push('/sites');
            });
        }
      });
    });
  }

  uploadFileBeforeSubmit(values) {
    if (!values.imageFile && !values.descriptionImageFile) {
      return Promise.resolve(values);
    }

    const { dispatch } = this.props;
    const promises = [];

    if (values.imageFile && values.imageFile.file) {
      promises.push(
        dispatch($uploadFile(values.imageFile.file))
          .then((result) => {
            return {
              ...values,
              imageFile: {
                ...values.imageFile,
                file: { ...values.imageFile.file, response: result.assets[0] },
              },
            };
          }),
      );
    }

    if (values.descriptionImageFile && values.descriptionImageFile.file) {
      promises.push(
        dispatch($uploadFile(values.descriptionImageFile.file))
          .then((result) => {
            return {
              descriptionImageFile: {
                ...values.descriptionImageFile,
                file: { ...values.descriptionImageFile.file, response: result.assets[0] },
              },
            };
          }),
      );
    }

    return Promise.all(promises).then((results) => {
      let v = { ...values };
      results.forEach((r) => {
        v = { ...v, ...r };
      });
      return v;
    });
  }

  mapFormValues(values) {
    const { zones } = this.props;
    const preparedValues = { ...values };

    preparedValues.zone = mapZone(zones.find((z) => z._id === values.zone));

    if (preparedValues.imageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.picture = preparedValues.imageFile.file.response;
      delete preparedValues.imageFile;
    }

    if (preparedValues.descriptionImageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.descriptionImage = preparedValues.descriptionImageFile.file.response;
      delete preparedValues.descriptionImageFile;
    }

    if (preparedValues.geoJSON) {
      preparedValues.geometry = JSON.parse(preparedValues.geoJSON);
      delete preparedValues.geoJSON;
    }

    if (preparedValues.location) {
      preparedValues.location = JSON.parse(preparedValues.location);
    }

    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const { isUploading, previewDataSrc } = this.state;
    const {
      form, site, sites, zones,
    } = this.props;

    const zoneHasError = this.hasError('zone');
    const nameHasError = this.hasError('name');
    const descriptionHasError = this.hasError('description');
    const geometryHasError = this.hasError('geometry');
    const hasSelectedImageFile = form.getFieldValue('imageFile')
      && form.getFieldValue('imageFile').fileList.length > 0;
    const hasSelectedDescriptionImageFile = form.getFieldValue('descriptionImageFile')
      && form.getFieldValue('descriptionImageFile').fileList.length > 0;

    return (
      <Row>
        <Col span={16}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              key="zone"
              label="Zone"
              validateStatus={zoneHasError ? 'error' : 'success'}
              help={zoneHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('zone', {
                rules: [{ required: true, message: 'Sélectionnez une zone' }],
                initialValue: site ? site.zone._id : zones[0]._id,
              })(
                <Select>
                  {zones.map((zone) => (
                    <Select.Option
                      key={zone._id}
                      value={zone._id}
                    >
                      {zone.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Form.Item
              key="name"
              label="Name"
              validateStatus={nameHasError ? 'error' : 'success'}
              help={nameHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Entrez le nom' }],
                initialValue: site ? site.name : '',
              })(
                <Input placeholder="Ex. Sidi Bousaid" />,
              )}
            </Form.Item>

            <Form.Item
              key="description"
              label="Description"
              validateStatus={descriptionHasError ? 'error' : 'success'}
              help={descriptionHasError || ''}
              {...formItemLayout}
            >
              {form.getFieldDecorator('description', {
                rules: [{ required: true, message: 'Entrez la description' }],
                initialValue: site ? site.description : '',
              })(
                <ReactQuill
                  placeholder="Une brève description caractéristique du site"
                />,
              )}
            </Form.Item>

            <Form.Item key="imageFile" label="Image" {...formItemLayout}>
              {form.getFieldDecorator('imageFile')(
                <Upload
                  name="files[]"
                  accept="image/*"
                  listType="picture"
                  defaultFileList={site && site.picture ? [{
                    uid: site.picture._id,
                    name: 'Current picture',
                    status: 'done',
                    url: `${ASSETS_ENDPOINT}/${site.picture.path}`,
                  }] : []}
                  beforeUpload={(file) => {
                    this.handleBeforeUpload(file);
                    return false;
                  }}
                >
                  {!hasSelectedImageFile && (
                    <Button>
                      <Icon type="upload" />
                      Sélectionner un fichier
                    </Button>
                  )}
                </Upload>,
              )}
              {!hasSelectedImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
            </Form.Item>

            <Form.Item key="descriptionImageFile" label="Image de description" {...formItemLayout}>
              {form.getFieldDecorator('descriptionImageFile')(
                <Upload
                  name="files[]"
                  accept="image/*"
                  listType="picture"
                  defaultFileList={site && site.descriptionImage ? [{
                    uid: site.descriptionImage._id,
                    name: 'Current picture',
                    status: 'done',
                    url: `${ASSETS_ENDPOINT}/${site.descriptionImage.path}`,
                  }] : []}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {!hasSelectedDescriptionImageFile && (
                    <Button>
                      <Icon type="upload" />
                      Sélectionner un fichier
                    </Button>
                  )}
                </Upload>,
              )}
              {!hasSelectedDescriptionImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
            </Form.Item>

            <Form.Item
              key="geometry"
              {...formItemLayoutWithOutLabel}
              validateStatus={geometryHasError ? 'error' : 'success'}
              help={geometryHasError || ''}
            >
              {form.getFieldDecorator('geoJSON', {
                rules: [{ required: true }],
                initialValue: site ? JSON.stringify(site.geometry) : '',
              })(
                <Input.TextArea disabled hidden />,
              )}
              {form.getFieldDecorator('location', {
                rules: [{ required: true }],
                initialValue: site ? JSON.stringify(site.location) : '',
              })(
                <Input disabled hidden />,
              )}
            </Form.Item>

            <Form.Item label="Bordures" {...formItemLayout}>
              <span>
                Utiliser l'outil polygon
                <img src={polygone} style={{ marginRight: '5px', marginLeft: '5px' }} alt="" />
                pour dessiner la bordure
              </span>
              <Map
                center={this.getMapCenter()}
                zoom={DEFAULT_MAP_ZOOM}
                style={{ height: '300px' }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution=""
                  minZoom="0"
                  maxZoom="22"
                />
                <FeatureGroup>
                  <EditControl
                    position="topright"
                    onCreated={this.handlePolygonCreated}
                    onDeleted={this.handlePolygonDeleted}
                    edit={{
                      edit: false,
                      remove: !!form.getFieldValue('geoJSON'),
                    }}
                    draw={{
                      circle: false,
                      circlemarker: false,
                      rectangle: false,
                      marker: false,
                      polyline: false,
                      polygon: !form.getFieldValue('geoJSON'),
                    }}
                  />

                  <GeoJSON data={site ? site.geometry : ''} />
                </FeatureGroup>

                <FullscreenControl
                  position="topleft"
                  forceSeparateButton
                />
              </Map>
            </Form.Item>

            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isUploading}
              >
                {isUploading ? 'En cours...' : 'Envoyer'}
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={8}>
          <PhonePreview>
            <List
              itemLayout="horizontal"
              dataSource={[
                {
                  name: form.getFieldValue('name'),
                  cover: previewDataSrc,
                },
                ...sites.map((s) => ({
                  name: s.name,
                  cover: `${ASSETS_ENDPOINT}/${s.picture.path}`,
                })),
              ]}
              renderItem={(item) => {
                return (
                  <List.Item className="SitePreview">
                    <img className="SitePreview__image" src={item.cover} alt="cover" />
                    <p className="SitePreview__text">{item.name}</p>
                  </List.Item>
                );
              }}
            />
          </PhonePreview>
        </Col>
      </Row>
    );
  }
}

SiteForm.propTypes = {
  site: PropTypes.object,
  sites: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

SiteForm.defaultProps = {
  site: null,
};

const WrappedSiteForm = Form.create({ name: 'site_form' })(SiteForm);

export default Wrapper(WrappedSiteForm);
