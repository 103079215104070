import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input, Form, Button, Select, notification,
} from 'antd';

import { $fetchUserList, $createUser, $editUser } from './state';
import { canEditPassword } from '../common/permissions';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const withStore = connect((state, props) => {
  const user = state.Users.list
    ? state.Users.list.find((u) => u._id === props.match.params.id)
    : null;

  return {
    processing: state.Activity.processingByTopic['Users.$fetchUserList'] || false,
    sites: state.Sites.list,
    user,
    hasEditPasswordPermission: user ? canEditPassword(state.Auth.user, user) : true,
  };
});

const Wrapper = (C) => withStore(C);

class UserForm extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchUserList());
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      user, form, dispatch, history,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        notification.error({ message: 'Please check form errors' });
        return null;
      }

      if (user) {
        dispatch($editUser(user._id, values))
          .then(() => {
            notification.success({ message: 'Utilisateur créé avec succès' });
            history.push('/users');
          });
      } else {
        dispatch($createUser(values))
          .then(() => {
            notification.success({ message: 'Utilisateur créé avec succès' });
            history.push('/users');
          });
      }
    });
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const {
      processing, user, form, hasEditPasswordPermission,
    } = this.props;

    if (processing !== false) {
      return null;
    }

    const nameHasError = this.hasError('name');
    const usernameHasError = this.hasError('user');
    const emailHasError = this.hasError('email');
    const groupHasError = this.hasError('group');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          key="name"
          label="Name"
          validateStatus={nameHasError ? 'error' : 'success'}
          help={nameHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input your fullname' }],
            initialValue: user ? user.name : '',
          })(
            <Input placeholder="Your fullname" />,
          )}
        </Form.Item>

        <Form.Item
          key="user"
          label="Username"
          validateStatus={usernameHasError ? 'error' : 'success'}
          help={usernameHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('user', {
            rules: [{ required: true, message: 'Please input username' }],
            initialValue: user ? user.user : '',
          })(
            <Input placeholder="Username" />,
          )}
        </Form.Item>

        <Form.Item
          key="email"
          label="Email"
          validateStatus={emailHasError ? 'error' : 'success'}
          help={emailHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input  youruser email' }],
            initialValue: user ? user.email : '',
          })(
            <Input placeholder="Your email" />,
          )}
        </Form.Item>

        <Form.Item
          key="group"
          label="Group"
          validateStatus={groupHasError ? 'error' : 'success'}
          help={groupHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('group', {
            rules: [{ required: true, message: 'Please select a group' }],
            initialValue: user ? user.group : 'author',
          })(
            <Select name="group">
              <Select.Option value="admin">admin</Select.Option>
              <Select.Option value="author">conservateur</Select.Option>
            </Select>,
          )}
        </Form.Item>

        {hasEditPasswordPermission && (
          <Form.Item
            key="password"
            label={user ? 'New password' : 'Password'}
            {...formItemLayout}
          >
            {form.getFieldDecorator('password')(
              <Input placeholder="Your password" type="password" />,
            )}
          </Form.Item>
        )}

        <Form.Item {...formTailLayout}>
          <Button
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

UserForm.propTypes = {
  sites: PropTypes.array.isRequired,
  user: PropTypes.object,
  form: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  hasEditPasswordPermission: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  user: null,
};

const WrappedUserForm = Form.create({ name: 'user_form' })(UserForm);

export default Wrapper(WrappedUserForm);
