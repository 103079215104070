import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Icon, Input, Button, message,
} from 'antd';

import { $login } from '../Auth/state';

const withStore = connect((state) => ({
  processing: state.Activity.processingByTopic['Auth.$login'] || false,
}));

const Wrapper = (C) => withStore(C);

class LoginView extends Component {
  state = {
    username: '',
    password: '',
  };

  login = () => {
    const { username, password } = this.state;
    const { dispatch, history } = this.props;

    return dispatch($login(username, password))
      .then(() => history.push('/'))
      .catch(() => message.error(`Nom d'utilisateur ou mot de passe incorrect`));
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { username, password } = this.state;

    return (
      <Form>
        <Form.Item>
          <Input
            id="username"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Nom d'utilisateur"
            value={username}
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Input
            id="password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block onClick={this.login}>
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

LoginView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default Wrapper(LoginView);
