import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ColorPicker from 'rc-color-picker';
import { Input, Form, Button, Upload, Icon, message } from 'antd';
import ReactQuill from 'react-quill';

import { $uploadFile, $createCategory, $editCategory } from './state';
import { ASSETS_ENDPOINT } from '../common/config';
import { isValidImage } from '../common/validate';

const withStore = connect((state, props) => ({
  category: state.Shared.categories.list.find((c) => c._id === props.id),
}));

const Wrapper = (C) => withStore(C);

class CategoryForm extends Component {
  state = {
    isUploading: false,
  };

  componentDidMount() {
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { category, form, onCloseModal, dispatch } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      let isValid = true;
      if (values.imageFile && values.imageFile.file) {
        isValid = isValidImage(values.imageFile.file);
      }

      if (!isValid) {
        message.error('Merci de sélectionner un fichier valide');
      }

      this.setState({ isUploading: true });
      this.uploadFileBeforeSubmit(values).then((valuesWithAssets) => {
        this.setState({ isUploading: false });

        const newValues = this.mapFormValues(valuesWithAssets);

        if (category) {
          dispatch($editCategory(category._id, newValues)).then(() => {
            onCloseModal();
          });
        } else {
          dispatch($createCategory(newValues)).then(() => {
            onCloseModal();
          });
        }
      });
    });
  }

  uploadFileBeforeSubmit(values) {
    const { dispatch } = this.props;

    if (values.imageFile && values.imageFile.file) {
      return dispatch($uploadFile(values.imageFile.file))
        .then((result) => {
          return {
            ...values,
            imageFile: {
              ...values.imageFile,
              file: { ...values.imageFile.file, response: result.assets[0] },
            },
          };
        });
    }

    return Promise.resolve(values);
  }

  mapFormValues(values) {
    const preparedValues = { ...values };

    if (preparedValues.imageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.picture = preparedValues.imageFile.file.response;
      delete preparedValues.imageFile;
    }

    if (preparedValues.color) {
      preparedValues.color = typeof preparedValues.color === 'string'
        ? preparedValues.color
        : preparedValues.color.color;
    }

    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const { isUploading } = this.state;
    const { form, category } = this.props;

    const nameHasError = this.hasError('name');
    const descriptionHasError = this.hasError('description');
    const colorHasError = this.hasError('color');
    const hasSelectedImageFile = form.getFieldValue('imageFile')
      && form.getFieldValue('imageFile').fileList.length > 0;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          key="name"
          label="Nom"
          validateStatus={nameHasError ? 'error' : 'success'}
          help={nameHasError || ''}
        >
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Entrez le nom' }],
            initialValue: category ? category.name : '',
          })(
            <Input placeholder="Site name" />,
          )}
        </Form.Item>

        <Form.Item
          key="description"
          label="Description"
          validateStatus={descriptionHasError ? 'error' : 'success'}
          help={descriptionHasError || ''}
        >
          {form.getFieldDecorator('description', {
            rules: [{ required: true, message: 'Entrez la description' }],
            initialValue: category ? category.description : '',
          })(
            <ReactQuill placeholder="Comment utiliser cette catégorie" />,
          )}
        </Form.Item>

        <Form.Item key="imageFile" label="Image">
          {form.getFieldDecorator('imageFile')(
            <Upload
              name="files[]"
              accept="image/*"
              listType="picture"
              defaultFileList={category && category.picture ? [{
                uid: category.picture._id,
                name: 'Current picture',
                status: 'done',
                url: `${ASSETS_ENDPOINT}/${category.picture.path}`,
              }] : []}
              beforeUpload={(file) => {
                return false;
              }}
            >
              {!hasSelectedImageFile && (
                <Button>
                  <Icon type="upload" />
                  Sélectionner un fichier
                </Button>
              )}
            </Upload>,
          )}
          {!hasSelectedImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
        </Form.Item>

        <Form.Item
          key="color"
          label="Couleur"
          validateStatus={colorHasError ? 'error' : 'success'}
          help={colorHasError || ''}
        >
          {form.getFieldDecorator('color', {
            rules: [{ required: true, message: 'Sélectionnez une couleur' }],
            initialValue: category ? category.color : '#000000',
          })(
            <ColorPicker
              defaultColor={category ? category.color : '#000000'}
              enableAlpha={false}
            />,
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isUploading}
          >
            {isUploading ? 'En cours...' : 'Envoyer'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

CategoryForm.propTypes = {
  id: PropTypes.string,
  category: PropTypes.object,
  form: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CategoryForm.defaultProps = {
  id: null,
  category: null,
};

const WrappedCategoryForm = Form.create({ name: 'category_form' })(CategoryForm);

export default Wrapper(WrappedCategoryForm);
