function isAdmin(account) {
  return account.user.group === 'admin';
}

export function canManageUsers(account) {
  return isAdmin(account);
}

export function canAssignAuthors(account) {
  return isAdmin(account);
}

export function canEditPassword(currentUser, user) {
  return currentUser._id === user._id;
}

export function canManageCategories(account) {
  return isAdmin(account);
}

export function canManageZones(account) {
  return isAdmin(account);
}

export function canManageSites(account) {
  return isAdmin(account);
}