import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  List, Row, Button, Modal, notification,
} from 'antd';

import TourRow from './TourRow';
import { $fetchTourList, $removeTour } from './state';

const withStore = connect((state) => ({
  tours: state.Tours.list
    ? state.Tours.list.filter((tour) => tour.site._id === state.Shared.currentSite)
    : [],
  currentSite: state.Shared.currentSite,
}));

const Wrapper = (C) => withStore(C);

class ToursView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchTourList());
  }

  handleDeleteTour = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous supprimer ce parcours ?',
      content: 'Cliquez OK pour continuer',
      onOk() {
        dispatch($removeTour(id))
          .then(() => {
            notification.success({ message: 'Le parcours a été supprimé avec succès' });
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  render() {
    const { tours, currentSite } = this.props;
    return (
      <Fragment>
        <Row type="flex" justify="end" style={{ marginBottom: 12 }}>
          <Button type="primary">
            <Link to={`/sites/${currentSite}/new-tour`}>Créer un parcours</Link>
          </Button>
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={tours}
          renderItem={(tour) => (
            <TourRow
              tour={tour}
              manageable
              onDelete={this.handleDeleteTour}
            />
          )}
        />
      </Fragment>
    );
  }
}

ToursView.propTypes = {
  tours: PropTypes.array.isRequired,
  currentSite: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(ToursView);
