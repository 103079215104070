import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Form, Button, Select, message } from 'antd';

import { $createSubCategory, $editSubCategory } from './state';

const withStore = connect((state, props) => ({
  categories: state.Shared.categories.list,
  subCategory: state.Shared.subCategories.list.find((c) => c._id === props.id),
}));

const Wrapper = (C) => withStore(C);

const mapCategory = (category) => ({
  link: 'Category',
  _id: category._id,
  display: category.name,
  color: category.color,
});

class SubCategoryForm extends Component {
  componentDidMount() {
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      subCategory, form, onCloseModal, dispatch,
    } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      const newValues = this.mapFormValues(values);

      if (subCategory) {
        dispatch($editSubCategory(subCategory._id, newValues)).then(() => {
          onCloseModal();
        });
      } else {
        dispatch($createSubCategory(newValues)).then(() => {
          onCloseModal();
        });
      }
    });
  }

  mapFormValues(values) {
    const { categories } = this.props;
    const preparedValues = { ...values };

    preparedValues.category = mapCategory(categories.find((c) => c._id === values.category));

    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const { form, subCategory, categories } = this.props;

    const categoryHasError = this.hasError('category');
    const nameHasError = this.hasError('name');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          key="category"
          label="Catégorie"
          validateStatus={categoryHasError ? 'error' : 'success'}
          help={categoryHasError || ''}
        >
          {form.getFieldDecorator('category', {
            rules: [{ required: true, message: 'Sélectionnez une catégorie' }],
            initialValue: subCategory && subCategory.category
              ? subCategory.category._id
              : categories[0]._id,
          })(
            <Select>
              {categories.map((category) => (
                <Select.Option
                  key={category._id}
                  value={category._id}
                >
                  {category.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item
          key="name"
          label="Nom"
          validateStatus={nameHasError ? 'error' : 'success'}
          help={nameHasError || ''}
        >
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Entrez le nom' }],
            initialValue: subCategory ? subCategory.name : '',
          })(
            <Input placeholder="Comment utiliser cette sous catégorie" />,
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

SubCategoryForm.propTypes = {
  id: PropTypes.string,
  subCategory: PropTypes.object,
  categories: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

SubCategoryForm.defaultProps = {
  id: null,
  subCategory: null,
};

const WrappeSubCategoryForm = Form.create({ name: 'subcategory_form' })(SubCategoryForm);

export default Wrapper(WrappeSubCategoryForm);
