import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Form, Button, message } from 'antd';
import ReactQuill from 'react-quill';
import L from 'leaflet';
import { Map, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import { EditControl } from 'react-leaflet-draw';

import { $createZone, $editZone } from './state';
import polygone from '../assets/polygone.png';

const withStore = connect((state, props) => ({
  zone: state.Shared.zones.list.find((c) => c._id === props.id),
}));

const Wrapper = (C) => withStore(C);

const DEFAULT_MAP_CENTER = [36.808286, 10.181066];
const DEFAULT_MAP_ZOOM = 14;

class ZoneForm extends Component {
  componentDidMount() {
    const { form } = this.props;
    form.validateFields();
  }

  handlePolygonCreated = (e) => {
    const { form } = this.props;

    form.setFieldsValue({
      geoJSON: JSON.stringify(e.layer.toGeoJSON()),
      location: JSON.stringify(L.latLngBounds(e.layer._bounds).getCenter()),
    });
  }

  handlePolygonDeleted = () => {
    const { form } = this.props;
    form.setFieldsValue({ geoJSON: null, location: null });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { zone, form, onCloseModal, dispatch } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      const newValues = this.mapFormValues(values);

      if (zone) {
        dispatch($editZone(zone._id, newValues)).then(() => {
          onCloseModal();
        });
      } else {
        dispatch($createZone(newValues)).then(() => {
          onCloseModal();
        });
      }
    });
  }

  mapFormValues(values) {
    const preparedValues = { ...values };

    if (preparedValues.geoJSON) {
      preparedValues.geometry = JSON.parse(preparedValues.geoJSON);
      delete preparedValues.geoJSON;
    }

    if (preparedValues.location) {
      preparedValues.location = JSON.parse(preparedValues.location);
    }

    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const { form, zone } = this.props;

    const nameHasError = this.hasError('name');
    const descriptionHasError = this.hasError('description');
    const geometryHasError = this.hasError('geometry');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          key="name"
          label="Nom"
          validateStatus={nameHasError ? 'error' : 'success'}
          help={nameHasError || ''}
        >
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Entrez le nom' }],
            initialValue: zone ? zone.name : '',
          })(
            <Input placeholder="Ex. Tunis" />,
          )}
        </Form.Item>

        <Form.Item
          key="description"
          label="Description"
          validateStatus={descriptionHasError ? 'error' : 'success'}
          help={descriptionHasError || ''}
        >
          {form.getFieldDecorator('description', {
            rules: [{ required: true, message: 'Entrez la description' }],
            initialValue: zone ? zone.description : '',
          })(
            <ReactQuill placeholder="Description de la zone" />,
          )}
        </Form.Item>

        <Form.Item
          key="geometry"
          validateStatus={geometryHasError ? 'error' : 'success'}
          help={geometryHasError || ''}
        >
          {form.getFieldDecorator('geoJSON', {
            rules: [{ required: true }],
            initialValue: zone ? JSON.stringify(zone.geometry) : '',
          })(
            <Input.TextArea
              disabled
              hidden
            />,
          )}
          {form.getFieldDecorator('location', {
            rules: [{ required: true }],
            initialValue: zone ? JSON.stringify(zone.location) : '',
          })(
            <Input disabled hidden />,
          )}
        </Form.Item>

        <Form.Item label="Bordures">
          <span>
            Utiliser l'outil polygon
            <img src={polygone} style={{ marginRight: '5px', marginLeft: '5px' }} alt="" />
            pour dessiner la bordure
          </span>

          <Map
            center={zone && zone.location
              ? [zone.location.lat, zone.location.lng]
              : DEFAULT_MAP_CENTER
            }
            zoom={DEFAULT_MAP_ZOOM}
            style={{ height: '300px' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution=""
              minZoom="0"
              maxZoom="22"
            />
            <FeatureGroup>
              <EditControl
                position="topright"
                onCreated={this.handlePolygonCreated}
                onDeleted={this.handlePolygonDeleted}
                edit={{
                  edit: false,
                  remove: !!form.getFieldValue('geoJSON'),
                }}
                draw={{
                  circle: false,
                  circlemarker: false,
                  rectangle: false,
                  marker: false,
                  polyline: false,
                  polygon: !form.getFieldValue('geoJSON'),
                }}
              />

              <GeoJSON data={zone ? zone.geometry : ''} />
            </FeatureGroup>

            <FullscreenControl
              position="topleft"
              forceSeparateButton
            />
          </Map>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

ZoneForm.propTypes = {
  id: PropTypes.string,
  zone: PropTypes.object,
  form: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ZoneForm.defaultProps = {
  id: null,
  zone: null,
};

const WrappedZoneForm = Form.create({ name: 'zone_form' })(ZoneForm);

export default Wrapper(WrappedZoneForm);
