import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  List, Skeleton, Icon, Button, notification, Row, Modal,
} from 'antd';

import { $removeUser, $fetchUserList } from './state';
import UserAvatar from '../components/UserAvatar';

const withStore = connect((state) => ({
  users: state.Users.list,
}));

const Wrapper = (C) => withStore(C);

class UsersView extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch($fetchUserList());
  }

  handleDeleteUser = (id) => {
    const { dispatch } = this.props;

    Modal.confirm({
      title: 'Voulez vous archiver cet utilisateur ?',
      content: 'Click ok to continue',
      onOk() {
        dispatch($removeUser(id))
          .then(() => {
            notification.success({ message: 'Utilisateur archivé avec succès' });
          })
          .catch((error) => notification.error({ message: error }));
      },
      onCancel() {},
    });
  }

  render() {
    const { users } = this.props;
    return (
      <div>
        <Row type="flex" justify="end" style={{ marginBottom: 12 }}>
          <Button type="primary">
            <Link to="/users/new">Créer</Link>
          </Button>
        </Row>
        <List
          dataSource={users}
          renderItem={(item) => (
            <List.Item
              className="ListItem"
              actions={[
                <Button
                  icon="delete"
                  onClick={() => this.handleDeleteUser(item._id)}
                  style={{ border: 'none' }}
                />,
                <Link to={`/users/${item._id}/edit`}>
                  <Icon type="edit" />
                </Link>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={(
                    <UserAvatar user={item} />
                  )}
                  title={(
                    <Link to="/users/">
                      {item.name}
                    </Link>
                  )}
                  description={item.email}
                />

                <Row type="flex" align="middle">{item.group}</Row>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

UsersView.propTypes = {
  users: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Wrapper(UsersView);
