import { API_ENDPOINT } from '../common/config';

import * as FetchHelper from '../common/fetch.helper';
import * as StateHelper from '../common/state.helper';

import { AuthService } from '../Auth/Auth.service';

import * as Activity from '../Shared/Activity.state';


/**
 * Module Name
 */

export const MODULE = 'Users';

/**
 * Initial State
 */

const INITIAL_STATE = {
  list: [],
};

/**
 * Fetch Users
 */

const fetchUserList = StateHelper.createAsyncOperation(MODULE, 'fetchUserList');

// Promise implementation
export function $fetchUserList() {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $fetchUserList.name));
    dispatch(fetchUserList.request());

    return fetch(`${API_ENDPOINT}/api/cockpit/listUsers?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          active: true,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(fetchUserList.success({ result })))
      .catch((error) => dispatch(fetchUserList.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $fetchUserList.name)));
  };
}

/**
 * Create User
 */

const createUser = StateHelper.createAsyncOperation(MODULE, 'createUser');

export function $createUser(payload) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $createUser.name));
    dispatch(createUser.request());

    return fetch(`${API_ENDPOINT}/api/cockpit/saveUser?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          ...payload,
          api_key: '',
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then(() => dispatch($fetchUserList()))
      .catch((error) => dispatch(createUser.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $createUser.name)));
  };
}

/**
 * Update User
 */

export const editUser = StateHelper.createAsyncOperation(MODULE, 'editUser');

export function $editUser(id, payload) {
  return (dispatch, getState) => {
    dispatch(Activity.$processing(MODULE, $editUser.name));
    dispatch(editUser.request());

    return fetch(`${API_ENDPOINT}/api/cockpit/saveUser?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          _id: id,
          ...payload,
          _mby: {
            id: getState().Auth.user._id,
            name: getState().Auth.user.name,
          },
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then((result) => dispatch(editUser.success({ result })))
      .catch((error) => dispatch(editUser.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $editUser.name)));
  };
}

/**
 * Remove User
 */

const removeUser = StateHelper.createAsyncOperation(MODULE, 'removeUser');

export function $removeUser(id) {
  return (dispatch) => {
    dispatch(Activity.$processing(MODULE, $removeUser.name));
    dispatch(removeUser.request());

    return fetch(`${API_ENDPOINT}/api/cockpit/saveUser?token=${AuthService.getAccessToken()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          _id: id,
          active: false,
        },
      }),
    })
      .then(FetchHelper.ResponseHandler, FetchHelper.ErrorHandler)
      .then(() => dispatch(removeUser.success({ meta: { id } })))
      .catch((error) => dispatch(removeUser.failure(error)))
      .finally(() => dispatch(Activity.$done(MODULE, $removeUser.name)));
  };
}


/**
 * Reducer
 */

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case fetchUserList.SUCCESS:
      return {
        ...state,
        list: action.result,
      };
    case editUser.SUCCESS:
      if (!state.list) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((user) => {
          if (user._id === action.result._id) {
            return {
              ...user,
              ...action.result,
            };
          }

          return user;
        }),
      };
    case removeUser.SUCCESS:
      if (!state.list) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((user) => {
          if (user._id === action.meta.id) {
            return {
              ...user,
              active: false,
            };
          }

          return user;
        }).filter((user) => user.active),
      };
    default:
      return state;
  }
}

/**
 * Persister
 */

export function persister({ users }) {
  return {
    users,
  };
}
