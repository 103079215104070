import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Upload, Icon, notification, message,
} from 'antd';
import L from 'leaflet';
import {
  Map, TileLayer, GeoJSON, Popup, FeatureGroup, Marker,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import FullscreenControl from 'react-leaflet-fullscreen';
import { belongsSegment } from 'leaflet-geometryutil';
import ReactQuill from 'react-quill';
import queryString from 'query-string';

import { $editTour, $createTour, $fetchTourList } from './state';
import { $fetchAttractionList } from '../Attractions/state';
import { $uploadFile } from '../Shared/state';
import { ASSETS_ENDPOINT } from '../common/config';
import { isValidImage } from '../common/validate';
import polyline from '../assets/polyline.png';

const DEFAULT_MAP_ZOOM = 14;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 14, offset: 4 },
};

const withStore = connect((state, props) => ({
  processing: state.Activity.processingByTopic['Tours.$fetchTourList'] || false,
  attractions: state.Attractions.list,
  sites: state.Sites.list,
  categories: state.Shared.categories.list,
  tours: state.Tours.list,
  tour: props.match.params.id && state.Tours.list
    ? state.Tours.list.find((tour) => tour._id === props.match.params.id)
    : null,
}));

const Wrapper = (C) => withStore(C);

class TourForm extends Component {
  refmarker = createRef();

  state = {
    site: null,
    category: null,
    isUploading: null,
  };

  componentDidMount() {
    const {
      categories, sites, location, history, dispatch,
    } = this.props;
    const parsedQuery = queryString.parse(location.search);

    dispatch($fetchTourList());
    dispatch($fetchAttractionList());

    if (parsedQuery.siteId && parsedQuery.categoryId) {
      this.setState({
        site: sites.find((s) => s._id === parsedQuery.siteId),
        category: categories.find((c) => c._id === parsedQuery.categoryId),
      });
    } else if (parsedQuery.siteId) {
      history.push(`/sites/${parsedQuery.siteId}`);
    } else {
      history.push('/sites');
    }

    return null;
  }

  getStepsFromPath(path) {
    const { attractions } = this.props;
    const segments = path.geometry.coordinates;
    const steps = [];
    let i = 0;

    for (i; i < segments.length - 1; i++) {
      // eslint-disable-next-line no-loop-func
      attractions.forEach((attraction) => {
        const latlng = L.latLng(attraction.location.lat, attraction.location.lng);
        const latlngA = L.latLng(segments[i][1], segments[i][0]);
        const latlngB = L.latLng(segments[i + 1][1], segments[i + 1][0]);

        const belongs = belongsSegment(latlng, latlngA, latlngB);

        if (belongs && !steps.find((a) => a._id === attraction._id)) {
          steps.push({
            display: attraction.name,
            _id: attraction._id,
            link: 'Attraction',
          });
        }
      });
    }

    return steps;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { tour, form, history, dispatch } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        message.error('Merci de corriger les erreurs');
        return null;
      }

      let isValid = true;
      if (values.imageFile && values.imageFile.file) {
        isValid = isValidImage(values.imageFile.file);
      }

      if (!isValid) {
        return message.error('Merci de sélectionner un fichier valide');
      }

      this.setState({ isUploading: true });
      this.uploadFileBeforeSubmit(values).then((valuesWithAssets) => {
        this.setState({ isUploading: false });

        const newValues = this.mapFormValues(valuesWithAssets);

        if (tour) {
          dispatch($editTour(tour._id, newValues))
            .then(() => {
              dispatch($fetchTourList());
              notification.success({ message: 'Le parcours a été édité avec succès!' });
              history.push('/tours');
            });
        } else {
          dispatch($createTour(newValues))
            .then(() => {
              dispatch($fetchTourList());
              notification.success({ message: 'Le parcours a été créé avec succès!' });
              history.push('/tours');
            });
        }
      });
    });
  }

  handleSelectSite = (site) => {
    const { history } = this.props;
    history.push({
      path: '/tours/new',
      search: `?siteId=${site._id}`,
    });

    this.setState({ site });
  }

  handlePolylineCreated = (e) => {
    const { form } = this.props;

    form.setFieldsValue({
      path: JSON.stringify(e.layer.toGeoJSON()),
    });
  }

  handlePolylineDeleted = () => {
    const { form } = this.props;
    form.setFieldsValue({ path: null });
  }

  uploadFileBeforeSubmit(values) {
    const { dispatch } = this.props;

    if (values.imageFile && values.imageFile.file) {
      return dispatch($uploadFile(values.imageFile.file))
        .then((result) => {
          return {
            ...values,
            imageFile: {
              ...values.imageFile,
              file: { ...values.imageFile.file, response: result.assets[0] },
            },
          };
        });
    }

    return Promise.resolve(values);
  }

  mapFormValues(values) {
    const { site, category } = this.state;
    const preparedValues = { ...values };

    preparedValues.site = {
      display: site.name,
      _id: site._id,
      link: 'Site',
    };

    preparedValues.category = {
      display: category.name,
      _id: category._id,
      link: 'Category',
    };

    if (preparedValues.imageFile) {
      // eslint-disable-next-line prefer-destructuring
      preparedValues.picture = preparedValues.imageFile.file.response;
      delete preparedValues.imageFile;
    }

    if (preparedValues.path) {
      preparedValues.path = JSON.parse(preparedValues.path);
      preparedValues.steps = this.getStepsFromPath(preparedValues.path);
    }

    return preparedValues;
  }

  hasError(fieldName) {
    const { form } = this.props;
    return form.isFieldTouched(fieldName) && form.getFieldError(fieldName);
  }

  render() {
    const { site, category, isUploading } = this.state;
    const {
      processing, tour, attractions, form,
    } = this.props;

    if (!site || !category || processing !== false) {
      return null;
    }

    const hasSelectedImageFile = form.getFieldValue('imageFile')
      && form.getFieldValue('imageFile').fileList.length > 0;

    const nameHasError = this.hasError('name');
    const descriptionHasError = this.hasError('description');
    const pathHasError = this.hasError('path');

    console.log('tours', this.props.tours)

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          key="name"
          label="Name"
          validateStatus={nameHasError ? 'error' : 'success'}
          help={nameHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Entrez le nom' }],
            initialValue: tour ? tour.name : '',
          })(
            <Input placeholder="Tour name" />,
          )}
        </Form.Item>

        <Form.Item
          key="description"
          label="Description"
          validateStatus={descriptionHasError ? 'error' : 'success'}
          help={descriptionHasError || ''}
          {...formItemLayout}
        >
          {form.getFieldDecorator('description', {
            rules: [{ required: true, message: 'Entre la description' }],
            initialValue: tour ? tour.description : '',
          })(
            <ReactQuill placeholder="Décrivez le parcours pour les visiteurs" />,
          )}
        </Form.Item>

        <Form.Item
          key="path"
          validateStatus={pathHasError ? 'error' : 'success'}
          help={pathHasError || ''}
          {...formItemLayoutWithOutLabel}
        >
          {form.getFieldDecorator('path', {
            rules: [{ required: true }],
            initialValue: tour ? JSON.stringify(tour.path) : '',
          })(
            <Input.TextArea disabled hidden />,
          )}
        </Form.Item>

        <Form.Item label="Steps" {...formItemLayout}>
          <span>
            Utiliser l'outil polyline
            <img src={polyline} style={{ marginRight: '5px', marginLeft: '5px' }} alt="" />
            pour dessiner le parcours
          </span>
          <Map
            center={[site.location.lat, site.location.lng]}
            zoom={DEFAULT_MAP_ZOOM}
            style={{ height: '300px' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution=""
              minZoom="0"
              maxZoom="22"
            />
            <FeatureGroup>
              <EditControl
                position="topright"
                onCreated={this.handlePolylineCreated}
                onDeleted={this.handlePolylineDeleted}
                edit={{
                  edit: false,
                  remove: !!form.getFieldValue('path'),
                }}
                draw={{
                  circle: false,
                  circlemarker: false,
                  rectangle: false,
                  marker: false,
                  polygon: false,
                  polyline: !form.getFieldValue('path'),
                }}
              />

              <GeoJSON key={tour ? tour._id : 'new'} data={tour ? tour.path : ''} />
            </FeatureGroup>

            {attractions.map((attraction) => (
              <Marker
                key={attraction._id}
                position={[attraction.location.lat, attraction.location.lng]}
                icon={L.icon({
                  iconUrl: `${ASSETS_ENDPOINT}/${attraction.coverPicture.path}`,
                  iconSize: [24, 24],
                })}
              >
                <Popup>{attraction.name}</Popup>
              </Marker>
            ))}

            <FullscreenControl
              position="topleft"
              forceSeparateButton
            />
          </Map>
        </Form.Item>

        <Form.Item key="imageFile" label="Image" {...formItemLayout}>
          {form.getFieldDecorator('imageFile')(
            <Upload
              name="files[]"
              accept="image/*"
              listType="picture"
              defaultFileList={tour && tour.picture ? [{
                uid: tour.picture._id,
                name: 'Current picture',
                status: 'done',
                url: `${ASSETS_ENDPOINT}/${tour.picture.path}`,
              }] : []}
              beforeUpload={(file) => {
                return false;
              }}
            >
              {!hasSelectedImageFile && (
                <Button>
                  <Icon type="upload" />
                  Sélectionner un fichier
                </Button>
              )}
            </Upload>,
          )}
          {!hasSelectedImageFile && <small>Le fichier image ne doit pas dépasser les 1MB</small>}
        </Form.Item>

        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isUploading}
          >
            {isUploading ? 'En cours...' : 'Envoyer'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

TourForm.propTypes = {
  tour: PropTypes.object,
  attractions: PropTypes.array,
  sites: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  processing: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

TourForm.defaultProps = {
  tour: null,
  attractions: [],
};

const WrappedTourForm = Form.create({ name: 'tour_form' })(TourForm);

export default Wrapper(WrappedTourForm);