import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import AttractionsView from './AttractionsView';
import AttractionForm from './AttractionForm';
import Breadcrumb from '../components/Breadcrumb';

const routes = [{
  name: 'Lieux',
  path: '/attractions',
  component: AttractionsView,
  icon: 'environment',
}, {
  name: 'Créer',
  path: '/attractions/new',
  component: AttractionForm,
}, {
  name: 'Editer',
  path: '/attractions/:id/edit',
  component: AttractionForm,
}];

const Attraction = () => {
  return (
    <Fragment>
      <Breadcrumb routes={routes} />

      <Switch>
        {routes.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}
      </Switch>
    </Fragment>
  );
};

export default Attraction;
