import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import L from 'leaflet';

import LandingView from './Entrance/LandingView';
import Entrance from './Entrance';
import Session from './Session';
import './App.css';
import 'leaflet/dist/leaflet.css';
import 'react-quill/dist/quill.snow.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import 'rc-color-picker/assets/index.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

const withStore = connect((state) => ({
  ready: state.Shared.ready,
  initialized: state.Shared.initialized,
  authenticated: state.Auth.authenticated,
}));

const propTypes = {
  ready: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const Wrapper = (C) => withRouter(withStore(C));

class App extends Component {
  state = {};

  render() {
    const { ready, initialized, authenticated } = this.props;

    if (!ready || (authenticated && !initialized)) {
      return <LandingView />;
    }

    return authenticated ? <Session /> : <Entrance />;
  }
}

App.propTypes = propTypes;

export default Wrapper(App);
