import React from 'react';
import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';

import { ASSETS_ENDPOINT } from '../common/config';

const AuthorRow = ({ user }) => {
  return (
    <List.Item className="ListItem">
      <List.Item.Meta
        avatar={<Avatar src={`${ASSETS_ENDPOINT}/${user.avatarUrl}`} />}
        title={user.name}
      />
    </List.Item>
  );
};

AuthorRow.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AuthorRow;
