import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import UsersView from './UsersView';
import UserForm from './UserForm';
import Breadcrumb from '../components/Breadcrumb';

const routes = [{
  path: '/users',
  component: UsersView,
  name: 'Users',
  icon: 'user',
}, {
  name: 'New',
  path: '/users/new',
  component: UserForm,
}, {
  name: 'Edit',
  path: '/users/:id/edit',
  component: UserForm,
}, {
  path: '/profile',
  component: UserForm,
  name: 'Profile',
  icon: 'user',
}];

const Users = () => {
  return (
    <Fragment>
      <Breadcrumb routes={routes} />

      <Switch>
        {routes.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}
      </Switch>
    </Fragment>
  );
};

export default Users;
