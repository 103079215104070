import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'antd';

import AuthorRow from '../Users/AuthorRow';

const withStore = connect((state, props) => ({
  users: state.Users.list,
  assignments: state.Shared.assignments
    ? state.Shared.assignments.list.filter((a) => a.site._id === state.Shared.currentSite)
    : [],
}));

const Wrapper = (C) => withStore(C);

const Team = ({ assignments, users }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={assignments}
      renderItem={(assignment) => {
        const user = users.find((u) => u._id === assignment.author);
        // When we delete a user and forgot to cleanup assignments
        if (!user) {
          return null;
        }

        return <AuthorRow user={user} />;
      }}
    />
  );
};

Team.propTypes = {
  users: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
};

export default Wrapper(Team);
