import React from 'react';

import LOGO from '../assets/logo.png';
import Loading from '../components/Loading';
import './LandingView.css';

const LandingView = () => (
  <div className="LandingView">
    <img src={LOGO} width={200} alt="logo" />
    <Loading />
  </div>
);

export default LandingView;
