import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import SitesView from './SitesView';
import SiteView from './SiteView';
import NewTourView from './NewTourView';
import SiteForm from './SiteForm';
import Breadcrumb from '../components/Breadcrumb';

const routes = [{
  path: '/sites',
  component: SitesView,
  name: 'Sites',
  icon: 'appstore',
}, {
  name: 'Créer',
  path: '/sites/new',
  component: SiteForm,
}, {
  name: 'Détails',
  path: '/sites/:id',
  component: SiteView,
}, {
  name: 'Ajouter parcours',
  path: '/sites/:id/new-tour',
  component: NewTourView,
}, {
  name: 'Editer',
  path: '/sites/:id/edit',
  component: SiteForm,
  exact: true,
}];

const Sites = () => {
  return (
    <Fragment>
      <Breadcrumb routes={routes} />

      <Switch>
        {routes.map((route) => (
          <Route key={route.path} exact {...route} />
        ))}
      </Switch>
    </Fragment>
  );
};

export default Sites;
